.tblcell {
	max-width: 600px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.tblcell:active, .tblcell:hover {
		overflow: auto;
		overflow-wrap: normal;
}
.tblcell:active span, .tblcell:hover span {
	position: relative;
	white-space: normal;
	/* compensate padding and border size to avoid jerking */
	z-index: 100;
	padding: 5px 5px 5px 0;
}
.bg-ams { background-color: #009bc9; }
.datepicker { 
    z-index: 9999 !important;
}
 